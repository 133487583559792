@import  "../../assets/_variables.scss";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  

.ant-table-wrapper {
    .ant-table-thead > tr > th {
        background: white;
        color: $brandTwo;
        font-weight: 600;
    }
    div, h3, td, th{
        font-size: 1rem;
        line-height: 12px;
    }
    .table-date{
        color: $blurredGrey;
    }
    th{
        background: none !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .ant-pagination-item-active {
        background: $brandOne;
        a {
            color: white;
        }
    }
}

.ant-form.ant-form-horizontal.readonly {
    margin: 0px;
    margin-bottom: 20px;
    
    .ant-form-item {
        margin: 0;
        + h3{
           margin-top: 20px;
        }
        .ant-form-item-label {
            line-height: 25px;
            height: 30px;
            text-align: left;
            label {
                font-size: 1rem;
                color: #757575;
                font-weight: 600;
                &:after {
                    content: '';
                }
            }
        }
        .ant-form-item-control {
            line-height: 25px;
            .ant-form-item-children{
                height: 25px;
                color: $brandTwo;
                font-size: 1rem;
            }
        }
    }
}

.ant-btn-secondary {
    border-color: $brandOne;
    color: $brandTwo;
    font-size: 1.1rem;
    line-height: 22px;
}

.anticon.anticon-close-circle.delete{
    color: $brandOne;
    font-size: 2rem;
    vertical-align: middle;
}

.ant-table-pagination{
    .ant-pagination-item {
        border-radius: 6px;
        font-size: 1rem;
    }
    .ant-pagination-item-active{
        border: none;
        background-color: $brandOne;
        a{
            color: white;
        }
    }
}

.tool-bar {
    background: $brandOne;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.07);
    height: 50px;
    margin-bottom: 40px;
    .container {
        padding: 10px 10px;
        .ant-btn {
            box-shadow: none;
            border: none;
        }
        h4 {
            display: block;
            line-height: 32px;
            color: white;
            font-size: 1.1rem;
            margin-right: 20px;
            .anticon {
                margin-right: 5px;
            }
        }
    }
    button.back {
        .ant-icon {
            margin-right: 15px;
        }
        font-weight: 600;
        color: white;
    }
}
.submenu{
    padding: 10px 20px;
    .ant-menu{ 
        float: right;
        .ant-menu-item{
            font-weight: bold;
            font-size: 1.2rem;
            line-height: 30px;
            border-bottom: 4px solid rgba(255, 255, 255, 0) !important;
            padding: 0 5px !important;
            margin: 0 10px;
            &.ant-menu-item-selected{
                color: $brandTwo;
                border-bottom: 4px solid $brandOne !important;
            }
            &.ant-menu-item-active{
                color: $brandTwo !important;
            }
        }        
        &.ant-menu-horizontal {
            border-bottom: none;
        }
    }
    
    > h1{
        font-weight: 500;
        font-size: 3rem;
        line-height: 3rem;
        letter-spacing: -0.02em;
        color: $titleColor;
        margin-bottom: .5rem;
    }
    >h4 {
        font-size: 1rem;
        font-weight: 500;
    }
}

.red {
    color: $error!important;
}
.green {
    color: $seaGreen!important;
}
.yellow {
    color: $brandOne!important;
}
.CO2Titles {
    text-align: left; 
    padding: 50px 0 50px 20px;
    h1 {
        line-height: 50px;
        font-size: 50px;
        margin: 0;
        font-weight: 500;
    }
    h2 {
        margin-bottom: 0;
    }
    &.error {
        h1 {
            color: $error;
        }
    }
}

.ant-modal {
    .CO2Titles {
        h1 {
            font-size: 35px;
            font-weight: 350;
        }
    }
}

.bold { font-weight: bold; }

.addNew{
    width: 38px;
    height: 38px;
    background-color: $brandOne;
    border-radius: 50%;
    padding: 8px;
    top: 0px;
    right: 15px;
    .anticon{
        svg{
            width: 20px;
            height: 20px;
            color: white;
        }
    }
    &.ant-btn-link:hover, &.ant-btn-link:focus {
        background-color: #ffd86f;
    }
}
