
$bgr: white;
$lightSilver: #D6D6D6;
$mediumSilver: rgb(163, 163, 163);
$blurBlack: #171717;
$titleColor: #2C2C2C;
$borderRadius: (
    xs: 0,
    md: 0,
    xl: 0
);
$error: #ff5a5a;
$seaGreen: #08B73C;
$modalPadding: 24px;

$brandOne: #eba900;
$brandTwo: #295135;

$blurredGrey: #757575;
$headerHeight: 100px;


:export { seaGreen: $seaGreen}
:export { error: $error }
:export { brandOne: $brandOne }