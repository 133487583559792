@import "../../assets/_variables.scss";

html {
  scroll-behavior: smooth;
}

html, body {
  height: 100%;
  background: $bgr;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
}

.layout {
  > .ant-layout-content {
    min-height: 400px;
    background: white;
    padding-bottom: 40px;

    > .container {
      margin-top: 30px;
    }

    .ant-layout-has-sider {
      > .ant-layout-content {
        padding-left: 20px;
      }
    }
  }
}

.ant-drawer-body {
  margin-top: 100px;

  h1 {
    color: $brandTwo;
    margin-bottom: 30px;
    font-weight: 800;
  }

  label {
    color: $brandTwo;
  }

  form {
    margin-bottom: 50px;
  }
}

.ant-spin {
  &.center {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
  }
}

.right {
  float: right;
}

.left {
  float: left;
}

button {

  + button {
    margin-right: 10px;
  }
}

/* LAYOUT */
.container {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .container {
    width: auto !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .container {
    width: 723px;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .container {
    width: 933px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1127px;
  }
}

@media only screen and (min-width: 1366px) {
  .container {
    width: 1350px;
  }
}
