@import '../../assets/_variables';
.card {
    margin-top: 10px;
    h1 {
        font-size: 1.8rem;
        font-weight: 600;
        color: $brandTwo;
    }
    h2 {
        font-size: 1.3rem;
        font-weight: 600;
        color: $brandTwo;
    }
    h3 {
        color: $brandTwo;
        font-size: 1.2rem;
        font-weight: 600;
    }
    > h3 {
        margin-left: 20px;
        font-size: 1.3rem;
        button {
            color: $brandTwo;
            height: 2rem;
        }
    }
    > div {
        min-height: 100px;
        border-radius: 5px;
        margin: 15px 15px 25px 15px;
        padding: 10px;
        box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
        background: #FFFFFF;
        border: 1px solid #FBFBFB;
        overflow-y: hidden;
    }
    .ant-divider-horizontal {
        margin: 0px;
    }
}
