.form-item-date {
    &.ant-calendar-picker{
        width: 100%;
    }
}

.card-depop, .depops-table{
    .anticon {
        font-size: 22px;
    }
    .ant-tabs-tab{
        font-size: 13px;
    }
    .ant-tabs-tab-active{
        font-weight: bold !important;
        color: black !important;
    }
    .depop-detail-header{
        display: flex;
        flex-direction: row;
        img{
            margin-right: 12px;
            width: 30px; 
            height: 30px;
            border-radius: 50%;}
    }
    .ant-table-wrapper{
        padding: 15px 10px;
    }
    .ant-table-tbody{
        td:first-child {
            padding-left: 0;
            padding-right: 0;
        }
        .ant-table-row {
            .name-row{
                display: flex;
                flex-direction: row;
                img {
                    align-items: flex-start;
                    order: 1;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
                .text{
                    span{
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    p{
                        margin-top: 6px;
                        margin-bottom: 6px;
                    }
                    margin-left: 10px;
                    flex: 2;
                    order: 2;
                }

            }
        }
    }
    .ant-divider {
        margin-bottom: 30px; 
    }
}

.anticon {
    &.anticon-play-circle{
        color: rgb(81, 177, 255);
    }
    &.anticon-clock-circle{
        color: rgb(253, 49, 13);
    }
    &.anticon-check-circle{
        color: rgb(49, 177, 70);
    }
}

.depop{
    .tool-bar{
        .container{
            >div{
                float: right;
                display: flex;
                vertical-align: middle;
                span{
                    padding: 0 10px;
                    color: white; 
                    font-style: normal;
                    font-weight: 500;
                }
                .anticon {
                    position: relative;
                    font-size: 22px;
                    top: 4px;
                }
                i{
                    border-radius: 50%;
                    max-height: 22px;
                    background-color: white;
                }
                button{
                    margin-left: 10px;
                }
            }
        }
    }
    .ant-btn-secondary{ span{ color: black !important; font-size: 13px; } }
    .ant-btn-ghost{ border: 1px solid white !important; font-size: 13px; }
    .ant-divider {
        margin-bottom: 30px; 
        margin-top: 15px;
    }
}

.depop-runs-list{
    .card{
        thead, th{ padding: 25px 8px !important; }
    }
}

.depop-data-list{
    padding: 20px 10px;
}

.depop-title-container{
    margin: 30px 20px;
    h2{
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -0.02em;
        color: #2C2C2C;
        margin-bottom: 4px;
    }
    span{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        opacity: 0.5;
    }
    > div:nth-child(2){
        text-align: right;
    }
    .depop-title-logo-container img{
        width: 62px;
        height: 62px;
        border-radius: 50%;
    }

}