@import  "../../assets/_variables.scss";
#root, .certificate {
    overflow: hidden;
}
.run-certificate{
    margin-top: 0px;
}
.certificate {
    display: block;
    max-width: 100%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    width: 1440px;
    .logo-holder {
        >.logo {
            width: 150px; 
            height: 150px;
            margin: 0 auto;
            position: relative;
            float: none;
            background-image: url('./../../assets/hs-logo-cert.png');
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .cert-header{
        margin-top: 20px;
        img{
            width: 64px;
            height: 64px;
            border-radius: 50%;
            margin-right: 15px;
        }
    }
    .cardc {
        border: 1px #e9e9e9 solid;
        border-radius: 5px;
        padding: 25px;
        overflow-y: hidden;
        &.depop-details{
            min-height: 350px;
            .certificate{
                margin: 0;
            }
        }
        &.details{
            min-height: 315px;
        }
        .legend {
            background-image: url(../../assets/legend-cert.png);
            background-size: cover;
            width: 250px;
            height: 25px;
            float: right;
            margin-right: 10px;
        }
    }
    h1 {
        display: block;
        width: 100%;
        color: $titleColor;
        font-size: 36px;
        text-align: center;
        margin: 0;

    }
    h2 {
        color: $titleColor;
        font-size: 30px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 0;
    }
    h3 {
        font-weight: bold;
        font-size: 18px;
        color: #444444;
    }
    h4 {
        font-size: 14px;
        color: #666666;
    }
    p {
        font-size: 14px;
        a {
            color: #080000;
        }
    }
    .green {
        color: $seaGreen;
    }
    ol {
        margin: 20px 0;
        padding-inline-start: 20px;
        li {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
    .ant-divider-horizontal {
        margin: 12px 0;
    }
    .cert-num {
        text-align: right;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $titleColor;
        margin-top: 10px;
        margin-bottom: 25px;
        > div {
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;
            margin-top: 5px;
        }
    }
    
    .CO2Titles{
        padding: 0 0 0 50px;
        h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            >span{
                font-size: 24px;
                margin-left: 5px;   
            }
        }
    }
    .ant-form.ant-form-horizontal.readonly {
        margin-bottom: 0px;
        padding: 0;
        .ant-form-item {
            padding-left: 10px;
            &:nth-child(even) {
                background: #F5F5F5;
            }
            label{
                font-weight: 600;
                font-size: 14px!important;
            }
            .ant-form-item-children{
                font-size: 14px!important;
            }
        } 
    } 
    .run-page {
        page-break-before: always;
    }
}