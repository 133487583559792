@import '../../../assets/variables';
.sider{
        border-right:none !important;
    .ant-menu-item{
        font-size: 1.1rem;
        &.ant-menu-item-selected {
            background: $brandOne;
            a {
                color: white;
                font-weight: 600;                
            }
        }
        a {
            color: $blurBlack;
        }
    }
}