@import '../../../assets/variables';

.delete-account-modal{
    span{
        font-size: 1rem;
    }
}

.ant-select-selection__choice{
    border: none !important;
    background-color: $brandOne !important;
    color: white !important;
    i{
       color: white !important;
    }
}

.client-details{
    button > i{
        stroke-width: 50px;
        stroke: white;
    }
    h1{
        font-size: 2.6rem;
        line-height: 36px;
        padding: 17px;
    }
    h2{
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 2rem;
    }
    h3{
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 16px;
    }
    .ant-divider-horizontal{
        margin: 15px 0;
    }
    .account-container{
        .account-name{
            color: $brandTwo;
            font-weight: bold;
        }
        .anticon-delete{
            position: relative;
            color: $mediumSilver;
            top: 8px;
            left: 25px;
            font-size: 1.7rem;
        }
    }
}

.delete-account-modal {
    .ant-form {
        padding: 0;
    }
    .buttons {
        padding-top: 20px; 
    }
}

.general-metrics {
    p{
        margin: 5px;
        color: #444444;
        font-size: 1rem;
    }
}