@import '../../../assets/variables';

.navigation {
    line-height: 22px!important;
    border-bottom: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 50%;
    text-align: center;
    width: 100%;
    .ant-menu-item {
        margin: 0 20px;
        padding: 0;
        a {
            color: $blurBlack;
            font-weight: 600;
        }
    }
}