@import '../../assets/_variables';

$blockBorder: 1px rgb(214, 214, 214) solid;
$blockBgr: rgb(245, 245, 245);

#home-content {
    text-align: center;
    h1 {
        margin-top: 50px;
        margin-bottom: 25px;
        font-size: 3rem;
    }
    .home-content-bar-col{
        
        >h3{
            font-weight: 600;
            font-size: 1.8rem;
            text-align: left;
            color: $brandTwo;
            margin: 20px 0 10px 0;
        }
        >p{
            text-align: left;
            margin: 0;
            padding: 0 !important;
            color: $blurBlack;
            >a{
                text-decoration: underline;
            }
        }
        >div{
            background-color: $blockBgr;
            height: 200px;
            padding-top: 80px;
        }
        >p{
            padding: 20px;
        }
    }

    .ant-carousel .slick-slide {
        text-align: center;
        height: 900px;
        line-height: 900px;
        background: $blockBgr;
        overflow: hidden;
        > div { 
            background-image: url('../../assets/imagehome.png');
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            > div {              
                h1 {
                    font-size: 3.6rem;
                    line-height: 36px;
                    font-weight: 600;
                    color: white;
                    margin-top: 420px;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);          
                }
                .ant-btn {
                    top: -400px;
                }
            }
        }
    }
}

.validate-password{
    h1{
        font-size: 32px;
        color: $brandTwo;
    }
    form{
        padding-left: 0;
        button{
            font-size: 16px;
            margin-bottom: 60px;
        }
    }
}
.validate-locker{
    display: flex;
    position: relative;
    width: 164px;
    height: 164px;
    background-color: $brandOne;
    border-radius: 50%;
    margin: 42px 0;
    i{
        margin: auto;
        color: white;
        font-size: 64px;
    }
}