@import  "../../../assets/_variables.scss";
.ant-col {
    .user-menu {
        float: right;
        margin-top: 25px;
        color: $blurBlack;
        font-weight: 600;
        .ant-avatar{
            margin: 3px 7px 0 0;
            float: left;
        }
        &.ant-dropdown-trigger {  
            white-space: nowrap; 
            min-width: 120px; 
            overflow: hidden;
            text-overflow: ellipsis; 
            top: 0px;
            display: block;
            padding: 0 5px;
            background: white;
            line-height: 32px;
            border-radius: 22px;
            border: none;
            z-index: 100;
            i.anticon-down {
                font-size: 1em;   
                font-weight: 600; 
                float: right;
                margin: 10px 5px;
            }
            &:hover{
                background: none!important;
                color: $brandOne!important;
            }
            &.ant-btn {
                bottom: 4rem;
                border: 1px solid $brandOne;
            }
            .ant-dropdown-menu-item:hover {
                color: white;
                a {
                    color: white;
                }
            }
        }
    }
}
