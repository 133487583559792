@import '../../assets/variables';

.ant-form-vertical {
    .ant-btn.ant-btn-block {
        &:first-of-type {
            margin-top: 25px;
        }
        &:not(:first-of-type) {
            margin-top: 15px;
        }
    }
}
.ant-radio-wrapper {
    margin-bottom: 1rem;
    > span:nth-child(2){
        display: inline-block;
        vertical-align: top;
    }
    > span:nth-child(2){
        display: inline-block;
    }
}

.ant-form, .customer-accounts-form, .padding-client-card{
    padding: 20px 10px;
    .ant-form-item-with-help{
        margin-bottom: 0;
    }
}

.ant-btn-default{
    border-color: $brandOne;
}

.full-width-number-cell .ant-input-number{
    width: 100%;
}

.avatar-uploader{
    .ant-upload-select-picture-card{
        border-radius: 50% !important;
        width: 76px !important;
        height: 76px !important;
        border: 1px solid $lightSilver !important;
        .ant-upload{
            padding: 0;
            &:hover{
                border-radius: 50% !important;
                background-color: hsla(138, 33%, 24%, 0.5);
                &::before{
                    position: absolute;
                    top: 16px;
                    left: 10px;
                    content: "Manage \A Photo";
                    color: white;
                    font-size: 15px;
                    font-weight: bolder;
                    white-space: pre;
                }
            }
            img{
                width: 62px !important;
                height: 62px !important;
                border-radius: 50% !important;
            }
        }
    }
}