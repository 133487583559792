@import '../../assets/_variables';

.ant-layout-header {
    background: white;
    position: fixed;
    z-index: 1;
    width: 100%;
    padding: 0px;
    height: auto;
    max-height: 120px;
    line-height: $headerHeight;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.04);
    >.container {
        height: $headerHeight;
        overflow: hidden;
    }
    + div{
        height: $headerHeight;
    }
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.04);
}
.logo {
    width: 70px;
    height: 70px;
    margin: 16px 24px 0px 0;
    float: left;
    background-image: url('../../assets/hs-logo.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.user-menu{
    .ant-avatar-icon{
        background-color: $brandOne;
    }
}